@font-face {
  font-family: "NeueMachina";
  font-weight: 700;
  src: url("../fonts/NeueMachina-Ultrabold.otf");
}

@font-face {
  font-family: "NeueMachina";
  font-weight: 400;
  src: url("../fonts/NeueMachina-Regular.otf");
}

@font-face {
  font-family: "NeueMachina";
  font-weight: 300;
  src: url("../fonts/NeueMachina-Light.otf");
}

ul {
  list-style-type: none;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 60.5%;
}

body {
  font-family: "NeueMachina";
  box-sizing: inherit;
  line-height: 1.6;
  font-weight: 400;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/bg.png");
  background-position: center;
}

.container {
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (max-width: 576px) {
    max-width: 100%;
  }
  @media (min-width: 576px) {
    max-width: 540px;
    margin-bottom: 30px;
  }

  @media (min-width: 767px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1140px) {
    max-width: 1500px;
  }
}

.App {
  min-height: 100vh;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #fff;
  stroke-width: 4.5;
  stroke-linecap: round;
}
.ham8 {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
  cursor: pointer;
  margin-right: 0.23rem;
}
.ham8 .top {
  stroke-dasharray: 40 160;
}
.ham8 .middle {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}
.ham8 .bottom {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}
.ham8.active .top {
  stroke-dashoffset: -64px;
}
.ham8.active .middle {
  //stroke-dashoffset: -20px;
  transform: rotate(90deg);
}
.ham8.active .bottom {
  stroke-dashoffset: -64px;
}

.navbar {
  &__circle {
    border-radius: 50%;
    width: 2.9rem;
    height: 2.9rem;
    margin-right: 0.8rem;
    background-color: #d1a3e0;
  }
  &__vector {
    border-radius: 50%;
    width: 2.7rem;
    height: 2.7rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.8rem;
  }
  &__addrr {
    color: #d1a3e0;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &__address {
    border-radius: 5rem;
    border: 2px solid #d1a3e0;
    display: flex;
    align-items: center;
    padding: 0.5rem 0.7rem;
  }
  opacity: 0;
  &__content {
    user-select: none;
    padding: 2rem 0;
    display: flex;
    align-items: center;
  }

  &__left {
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  &__logo {
    img {
      vertical-align: bottom;
    }
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 3rem;
      @media (max-width: 992px) {
        margin-right: 1rem;
      }
    }
    &:nth-child(1) {
      @media (max-width: 767px) {
        display: none;
      }
    }
    &:nth-child(2) {
      @media (max-width: 767px) {
        display: none;
      }
      a {
        background-color: #d1a3e0;
        padding: 1.5rem 3rem;
        border-radius: 5rem;
        box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
        @media (max-width: 992px) {
          padding: 1.3rem 1rem;
        }
      }
    }
    &:nth-child(3) {
      border: 2px solid #d1a3e0;
      color: #d1a3e0 !important;
      cursor: pointer;
      padding: 0.9rem 3rem;
      @media (max-width: 992px) {
        padding: 0.2rem 0.4rem;
        font-size: 0.1rem;
        text-align: center;
        margin-left: 1rem;
      }
      border-radius: 5rem;
      box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
    }
    a {
      font-size: 1.3rem;
      vertical-align: text-top;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      font-weight: bold;
      &:link,
      &:visited,
      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.intro {
  // min-height: calc(100vh - 87.8px);
  margin-top: 135px;
  @media (max-width: 576px) {
    min-height: auto;
    margin-top: 50px;
  }
  display: flex;
  align-items: center;
  &__left,
  &__right {
    align-self: flex-start;
    width: 50%;
    @media (max-width: 992px) {
      width: 100%;
    }
    text-align: center;
  }
  &__left {
    @media (max-width: 1270px) {
      transform: scale(0.8);
    }
    position: relative;
  }

  &__right {
    padding-top: 10px;
  }

  &__content {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    align-items: center;
  }
}

.nft {
  padding: 8px;
  &__info {
    svg {
      vertical-align: middle;
    }
    color: #ff9e67;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    display: flex;
    align-items: center;
  }
  &__per {
    margin-left: 1rem;
  }
  &__minted {
    margin-right: 1rem;
  }

  &__main-image {
    z-index: 111;
  }
  height: 541px;
  @media (max-width: 992px) {
    height: 475px;
  }

  z-index: 111;
  position: relative;
  @media (max-width: 576px) {
    // display: none;
  }
  opacity: 0;
  width: 404px;
  @media (max-width: 1150px) {
    width: 400px;
  }
  @media (max-width: 992px) {
    width: 300px;
    margin-bottom: 2rem;
  }
  margin: auto;
  background-color: #14082f;
  border-radius: 1rem;
  margin-bottom: 20px;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);

  &__image {
    .img {
      &-1 {
        z-index: 2;
        transform: translate(73px, -9px) rotate(15deg) scale(0.8);
      }
      &-2 {
        z-index: 2;
        transform: translate(-75px, 8px) rotate(-15deg) scale(0.8);
      }
      &-3 {
        z-index: 1;
        transform: translate(138px, -9px) rotate(24deg) scale(0.6);
      }
      &-4 {
        z-index: 1;
        transform: translate(-149px, 12px) rotate(-27deg) scale(0.6);
      }
    }
    & > img {
      position: absolute;
      top: 0;
      left: 0;
    }
    z-index: 11;
    position: relative;
    img {
      width: 100%;
      &.main {
        z-index: 10;
      }
      object-fit: cover;
    }
  }
  &__intro {
    padding-bottom: 2rem;
    text-align: left;
    bottom: 0;
    position: absolute;
    padding-left: 2rem;
  }
  &__text {
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
  }
  &__number {
    color: #ff9e67;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }
  &__total {
    display: flex;
    align-items: center;
    margin-left: -5px;
    margin-top: 0.8rem;
  }
  &__eth {
    font-size: 1.3rem;
    color: #a2a2a2;
  }
  &__avatar {
    width: 4rem;
    margin-right: 1rem;
    height: 4rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    position: relative;
    img {
      position: absolute;
      left: 54%;
      top: 46%;
      transform: translate(-50%, -50%);
    }
  }
}

.minting {
  text-align: left;
  &__title {
    opacity: 0;
    color: #ff9e67;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @media (max-width: 992px) {
      text-align: left;
    }
  }
  &__heading {
    h5 {
      font-weight: 300;
    }
    margin-bottom: 2rem;
    font-size: 6rem;
    @media (max-width: 576px) {
      font-size: 4.5rem;
    }
    line-height: 1.1;
    font-size: 400;
    text-transform: uppercase;

    span {
      opacity: 0;
      display: block;
    }
  }
  &__live {
    width: 62%;
    @media (max-width: 1300px) {
      width: 80%;
    }
    @media (max-width: 1150px) {
      width: 100%;
    }
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    &-left,
    &-right {
      width: 50%;
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: end;
      p {
        font-size: 1.6rem;
        line-height: 0;
      }
      span {
        font-size: 1.3rem;
        margin-left: 0.6rem;
        font-weight: bold;
      }
    }
    &-left {
      display: flex;
      align-items: center;
      span {
        margin-right: 0.6rem;
        background-color: green;
        opacity: 0.7;
        display: inline-block;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        box-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 10%);
        margin-top: -3px;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }

  &__disabled {
    width: 62%;
    @media (max-width: 1300px) {
      width: 80%;
    }
    @media (max-width: 1150px) {
      width: 100%;
    }
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    &-left,
    &-right {
      width: 50%;
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: end;
      p {
        font-size: 1.6rem;
        line-height: 0;
      }
      span {
        font-size: 1.3rem;
        margin-left: 0.6rem;
        font-weight: bold;
      }
    }
    &-left {
      display: flex;
      align-items: center;
      span {
        margin-right: 0.6rem;
        background-color: #ff0000;
        opacity: 0.7;
        display: inline-block;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        box-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 10%);
        margin-top: -3px;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
  &__form {
    opacity: 0;
    width: 62%;
    @media (max-width: 1300px) {
      width: 80%;
    }
    @media (max-width: 1150px) {
      width: 100%;
    }
  }
  &__calc {
    user-select: none;    
    background: #1c0b3f;
    padding: 14px;
    width: 100%;
    @media (max-width: 576px) {
     
    }
    border-radius: 10px;
    display: flex;
    align-items: center;
    & > div {
      
    }
  }
  &__eth{
    width: calc(100% - 30px);
  }
  &__lock {
    text-align: end;
    opacity: 0.4;
    width: 30px;
    img {
      // width: 19%;
    }
  }
  &__add {
    user-select: none;

    display: flex;
    align-items: center;
    width: 48%;
    margin-right: 2%;
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 1.3rem;
    & > div:nth-child(1) {
      text-align: start;
      span {
        border: 1px solid #fff;
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.5rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    & > div:nth-child(2) {
      text-align: center;
    }
    & > div:nth-child(3) {
      text-align: end;
      span {
        border: 1px solid #fff;
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.5rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        margin-left: auto;
        align-items: center;
        justify-content: center;
      }
    }
    & > div {
      font-size: 1.9rem;
      width: 33.333333%;
    }
  }
  &__accept {
    opacity: 0;
    margin-bottom: 4rem;
    margin-top: 4rem;
    display: flex;
    align-items: center;
  }
  &__message {
    font-size: 1.9rem;
    font-weight: 200;
    @media (max-width: 576px) {
      font-size: 1.5rem;
    }
    span {
      font-weight: bold;
    }
  }
  &__checkbox {
    cursor: pointer;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 20%);
    height: 2rem;
    width: 2rem;
    border: 2px solid #fff;
    position: relative;
    margin-right: 2rem;
    margin-top: -4px;
    &.active {
      &::before {
        position: absolute;
        content: "";
        height: 1.1rem;
        width: 1.1rem;
        background-color: #d1a3e0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__eth {
    font-size: 1.8rem;
    @media (max-width: 576px) {
      font-size: 1.5rem;
    }
  }
  &__button {
    margin-bottom: 3rem;
    opacity: 0;
    text-align: center;
  }
}
.mt-3 {
  margin-top: 1rem;
}
.flex-me {
  display: flex;
  align-items: center;
}

@keyframes bubble {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 0.7;
  }
}

.btn {
  padding: 2rem 8rem;
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: button;
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  vertical-align: middle;
  font-size: 1.6rem;
  font-family: inherit;
  border-radius: 5rem;
  cursor: pointer;
  outline: none;
  border: none;
  user-select: none;
  letter-spacing: 0.04rem;
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;

  &-primary {
    margin-top: 63px;
    margin-top: 63px;
    background-color: #d1a3e0;
    color: #000 !important;
  }
}

.mobile-intro {
  display: none;
  @media (max-width: 576px) {
    // display: block;
  }
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 440px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
  }
}

.mobile__content {
  position: absolute;
  bottom: 20px;
  right: 20px;
  span {
    color: #ff9e67;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }
}
.trans {
  transition: all 0.1s cubic-bezier(0.36, 0, 0.66, -0.56);
}

.nft__main-image {
  position: absolute;
}
.mobile__menu {
  @media (min-width: 767px) {
    display: none;
  }
}
.mobile__list {
  padding: 16px;
  border-top: 1px solid #d1a3e0;
  color: #fff !important;
  font-size: 1.8rem;
  text-transform: uppercase;
  li:not(:last-child) {
    margin-bottom: 1rem;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
}

.max-mint {
  font-family: "NeueMachina";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 110%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.nft-minted {
  font-family: "NeueMachina";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 110%;
  text-align: center;
  span:first-child {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    color: #ffffff;
    img {
      position: absolute;
      left: 0;
      top: -2px;
    }
  }
  span:last-child {
    color: #ffa25e;
  }
}

.top-alert {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  left: 0px;
  top: 105px;
  background: rgba(151, 78, 255, 0.4);
  font-family: "NeueMachina";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  color: #ffffff;
}

.popup {
  &__overlay {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(#251273, 0.6);
    width: 100vw;
    z-index: 11111;
  }

  &__content {
    position: relative;
    width: 380px;
    height: 300px;
    &.h-600 {
      height: 600px;
      width: 420px;
    }
    @media (max-width: 767px) {
      width: 380px;
    }
    @media (max-width: 500px) {
      width: 330px;
    }
    background-color: #fff;
    border-radius: 29px;
    box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 133;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 67px 33px;
    animation: popup 0.3s ease-in-out 0.1s backwards;
  }

  &__image {
    img {
      width: 96px;
      height: 96px;
      margin-bottom: 28px;
    }
  }

  &__info {
    // width: 375px;
    // height: 42px;
    font-family: "NeueMachina";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    // line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #974eff;
    // position: absolute;
    bottom: 30px;
    padding: 15px 25px;
  }

  &__message {
    font-family: "NeueMachina";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    padding: 0 20px;
  }

  &__buttons {
    margin-top: 3rem;
    width: 71%;
    @media (max-width: 767px) {
      width: 90%;
    }

    > button {
      margin-bottom: 2rem;
      display: block;
      width: 100%;
      color: #d1a3e0;
      font-family: inherit;
      padding: 1.1rem 3rem;
      background: none;
      font-weight: bold;
      cursor: pointer;
      border: 1px solid #d1a3e0;
      font-size: 1.3rem;
      text-transform: uppercase;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:first-child {
        font-size: 1.3rem;
        padding: 1.2rem 3rem;
        img {
          margin-right: 1rem;
          width: 11%;
        }
      }
      & > div {
        margin-right: 1rem;
        background: radial-gradient(
          100% 100% at 0% 50%,
          #5d9df6 0%,
          #006fff 100%
        );
        border-radius: 50%;
        width: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.8rem;
        img {
          width: 60%;
        }
      }
    }
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 18px;
    cursor: pointer;
  }
}

.wallet-btn {
  padding: 0.74rem 3rem;
}

@keyframes popup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nfts-images {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin-bottom: 9rem;
  flex-wrap: wrap;
  padding: 0 50px;
}

.blank-nft-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(96, 83, 125, 0.06);
  border: 2px solid rgba(80, 47, 91, 0.3);
  border-radius: 11.1613px;
  font-family: "NeueMachina";
  font-style: normal;
  font-weight: 400;
  font-size: 4rem;
  line-height: 62px;
  text-align: center;
  text-transform: uppercase;
  color: #342c3e;
  width: 421.86px;
  height: 535px;
}

.nfts-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h2 {
    font-family: "NeueMachina";
    font-style: normal;
    font-weight: 400;
    font-size: 4rem;
    line-height: 62px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }

  p {
    font-family: "NeueMachina";
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 110%;
    color: #ffffff;
  }
}
.nft-image {
  width: 100%;
}

.nft-item {
  width: 421.86px;
  height: 535px;
  background: #14082f;
  border-radius: 11.1613px;
  padding: 20px;

  h4 {
    font-family: "NeueMachina";
    font-style: normal;
    font-weight: 800;
    font-size: 20.5549px;
    line-height: 24px;
    letter-spacing: 0.355em;
    margin-top: 10px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .meta-info {
    & > span {
      display: flex;
      align-items: center;
    }

    .stars {
      font-family: "NeueMachina";
      font-style: normal;
      font-weight: 800;
      font-size: 19.5549px;
      text-transform: uppercase;
      color: #ff9e67;
    }
  }

  .eth-p {
    font-family: "NeueMachina";
    font-style: normal;
    font-weight: 400;
    font-size: 14.0903px;
    line-height: 19px;
    text-transform: uppercase;
    color: #a2a2a2;
  }
}
